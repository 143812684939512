import { Divider, Drawer, Stack, ToggleButton, ToggleButtonGroup } from "@mui/material"
import { navBtnStyle, titleMargin } from "../../components/layout/style";
import { useLocation, useNavigate } from "react-router-dom";
import gearIcon from '../../assets/inline-menu-item/icon-wrapper/14px.png';
import TetronLogo from '../../assets/Logo.png'
import { useEffect, useState } from "react";
import { parseLocationPathName } from "../../utils";
import { useSidebarModel } from "./SidebarModel";


export const Sidebar=()=>{

    const drawerWidth = 256;
    const navigate = useNavigate()
    const sidebarModel = useSidebarModel();

    const drawer = (
        <div style={{
          marginTop: '18px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
          <img src={TetronLogo} alt="" width={'140px'} style={{ margin: 'auto' }} />
            <Stack
            
            sx={{
              color: '#AFAFAF',
              marginTop: '73px',
              width:'100%',
            }}
            spacing={2}>
            <ToggleButtonGroup
            fullWidth
              orientation="vertical"
              value={sidebarModel.view}
              exclusive
              onChange={sidebarModel.handleChange}
            >
              
              <ToggleButton onClick={() => { navigate('/') }} sx={navBtnStyle} value="main" >
                <img src={gearIcon}></img>
                <p style={titleMargin}>Главная</p>
              </ToggleButton>
              <ToggleButton onClick={() => { navigate('/organisations') }}  sx={navBtnStyle} value="organisations" >
                <img src={gearIcon}></img>
                <p style={titleMargin}>Мои организации</p>
              </ToggleButton>
              <ToggleButton onClick={() => { navigate('/services') }} sx={navBtnStyle} value="services">
                <img src={gearIcon}></img>
                <p style={titleMargin}>Каталог сервисов</p>
              </ToggleButton>
              <ToggleButton onClick={() => { navigate('/analytics') }} sx={navBtnStyle} value="analytics">
                <img src={gearIcon}></img>
                <p style={titleMargin}>Аналитика</p>
              </ToggleButton>
              
              
            </ToggleButtonGroup>
            </Stack>
          <Divider />
        </div>
      );

    return <Drawer
    variant="permanent"
    sx={{
      display: { xs: 'none', sm: 'block' },
      '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: '#2A2A2A' },
    }}
    open
  >
    {drawer}
  </Drawer>
}
