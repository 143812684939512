import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { parseLocationPathName } from "../../utils";


export const useSidebarModel=()=>{

    const drawerWidth = 256;
    const navigate = useNavigate()
    const [view, setView] = useState('list');
    const location = useLocation();
    const sidebarPaths = ['organisations', "", 'services', 'analytics']


    useEffect(()=>{
        console.log('sidebar location')
        console.log(location)
        const parsed = parseLocationPathName(location.pathname)
        console.log('parsed')
        console.log(parsed)
        console.log("CHECK")
        parsed[0]==='' ? setView('main') : setView(parsed[0])
    }, [location])

    const handleChange = (event: React.MouseEvent<HTMLElement>, nextView: string) => {
        setView(nextView);
      };

    return{
        handleChange,
        view,
    }
}