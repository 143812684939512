import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { createEmployeeRequest, editEmployeeRequest, editSubdivisionRequest, getEmployeesRequest, getPositionsRequest, getProductsRequest, getRolesRequest } from "../../../../services/EmployeeService";
import { getSubdivisionsRequest } from "../../../../services/SubdivisionService";
import { clearEmployeeBuffer, clearEmployees, setEmployeeBuffer, setEmployeeData, setEmployees } from "./OrganisationEmployeesSlice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks/redux";
import { Contact,  Employee, Position, Product, Role,  } from "./OrganisationEmployees-types";
import { usePasswordInpModel } from "../../../../components/passwordInput/passwordInputModel";
import { statusType } from "../../Organisation-types";
import { OrganisationsTabsLabels } from "../OrganisationAllInfoModule/OrganisationAllInfo-types";

import { property } from "lodash";
import { phoneMaskValidate } from "../../../../utils";

export const useEmployeesModel = () => {

    const { id } = useParams();


    const dispatch = useAppDispatch();
    const {employees, employeeData} = useAppSelector(state=>state.emplReducer) 

    const [isModalOpen, setModalOpen] = useState<boolean>(false)

    const handleOpenModal = () => {
        setModalOpen(true)
    }


    const handleSetEditEmployeeData = (employee: Employee) => {

        // employee = {...employee, positionId: employee.position?.id}
        // employee = {...employee, departmentId: employee.department?.id}
        // delete employee.position
        // delete employee.department

        //console.log(employee)
        setEditEmployeeData(employee)
        dispatch(setEmployeeData(employee))
    }

    useEffect(()=>{

      dispatch(setEmployeeData(employees.find((item:Employee)=> item.id == employeeData?.id)))  
    }, [employees])


    const [editEmployeeData, setEditEmployeeData] = useState<Employee | null>(null)
    
    useEffect(()=>{
        if(!isModalOpen){
            setEditEmployeeData(null)
            dispatch(setEmployeeData(undefined))
        } 
    },[isModalOpen])



    return {
        isModalOpen,
        setModalOpen,
        handleOpenModal,

        handleSetEditEmployeeData,
        editEmployeeData,
    }
}