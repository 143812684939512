import axios from "axios";
import { refreshRequest } from "../services/AuthService";
import { Buffer } from "buffer";
import sanitizedConfig from "../Config";


export const API_URL = sanitizedConfig.REACT_APP_ORGANISATIONS_API_URL

export const $api = axios.create({
    baseURL: API_URL,
}
)

export const $file =axios.create({
    baseURL:API_URL,
    responseType:"blob"
})


const needToUpdate = (token: string) => {

    let currentDate = new Date();

    let decodedToken = JSON.parse(atob(token.split('.')[1]).toString());

    console.log(decodedToken.exp - decodedToken.iat)
    console.log(decodedToken)
    console.log("refresh: ", currentDate.getTime() > decodedToken.exp * 1000)
    return currentDate.getTime() > decodedToken.exp * 1000

}


$api.interceptors.request.use(async (config) => {
    console.log(config)
    config.baseURL = sanitizedConfig.REACT_APP_ORGANISATIONS_API_URL
    if (config.url === '/v1/web/auth/refresh') return config
    let currentAccessToken = localStorage.getItem('accessToken')
    if (!!currentAccessToken) {
        //  if (needToUpdate(currentAccessToken)) {
        //      await refreshRequest()
        //  }
        config.headers.Authorization = `Bearer ${currentAccessToken}`
        config.headers.Cookie = localStorage.getItem('refreshToken')
    }
    return config
}, (error) => {
    console.log('Ошибка!')
    console.log(error)
})


$file.interceptors.request.use(async (config) => {
    console.log(config)
    config.baseURL = sanitizedConfig.REACT_APP_ORGANISATIONS_API_URL
    if (config.url === '/v1/web/auth/refresh') return config
    let currentAccessToken = localStorage.getItem('accessToken')
    if (!!currentAccessToken) {
        //  if (needToUpdate(currentAccessToken)) {
        //      await refreshRequest()
        //  }
        config.headers.Authorization = `Bearer ${currentAccessToken}`
        config.headers.Cookie = localStorage.getItem('refreshToken')
    }
    return config
}, (error) => {
    console.log('Ошибка!')
    console.log(error)
})



// $api.interceptors.response.use((config)=>{
//     config.data.access_token && localStorage.setItem('accessToken', config.data.access_token)
//     config.data.refresh_token && localStorage.setItem('refreshToken', config.data.access_token)
//     console.log('Успешно')
//     console.log(config)
//     return config
// },(error)=>{
//     console.log('Ошибка!')
//     console.log(error)
// })

