export const navBtnStyle = {

    "&": {
        color: '#AFAFAF',
        textTransform: 'none',
        justifyContent: 'flex-start',
        height: '40px',
    },
    "&:hover": {
        cursor: 'pointer',
    },
    "&.Mui-selected": {
        color: '#009FDA',
        backgroundColor: '#E6F7FF',
    },
    "&.Mui-selected:hover": {
        color: '#009FDA',
        backgroundColor: '#E6F7FF',
    }
}

export const titleMargin = {
    marginLeft: '10px'
}