
import { FC, ReactNode, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import classes from './layout.module.css'


import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { NavButton } from "./components/NavButton/NavButton";
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import { Avatar, Badge, Container, Stack, Tab, Tabs, ToggleButton, ToggleButtonGroup } from "@mui/material";
import TetronLogo from '../../assets/Logo.png'
import TabList from "@mui/lab/TabList";
import gearIcon from '../../assets/inline-menu-item/icon-wrapper/14px.png';
import { navBtnStyle, titleMargin } from "./style";
import { Location } from "../../modules/LocationDivider/Location";
import { Sidebar } from "../../modules/Sidebar/Sidebar";
const drawerWidth = 256;


export const Layout = () => {

  // const [mobileOpen, setMobileOpen] = useState(false);
  // const [view, setView] = useState('list');

  // const handleChange = (event: React.MouseEvent<HTMLElement>, nextView: string) => {
  //   setView(nextView);
  // };

  // const handleDrawerToggle = () => {
  //   setMobileOpen(!mobileOpen);
  // };



  return (
    <Box sx={{
      display: 'flex',
      //  backgroundColor: 'blue',
      flexDirection: 'column'
    }}>
      <CssBaseline />
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 }, color: 'black' }}
        aria-label="mailbox folders"

      >
       <Sidebar/>
      </Box>

      <div style={{ marginLeft: `${drawerWidth}px` }}>
        <Box padding={2}>
        <AppBar position="static" sx={{ width: '100%', backgroundColor: 'white', display: 'flex', flexDirection: "row", justifyContent: 'flex-end', boxShadow:'none' }}>
          <Toolbar sx={{ width: '250px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Badge badgeContent={4} color="secondary">
              <NotificationsNoneOutlinedIcon color="action" />
            </Badge>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar></Avatar>
              <Typography marginLeft={'10px'} color={'#3E3E3E'}>Тест Тестович</Typography>
            </div>
          </Toolbar>
        </AppBar>
        <Location/>
        </Box>
        

        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3, width: '100%', backgroundColor: '#F0F2F5', padding: 0 }}
        >
          <Outlet />
        </Box>
      </div>

    </Box>
  );
}