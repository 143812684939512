import React from 'react'
import { Box, Button, Radio, RadioGroup, Toolbar, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import { blueContainedButtonStyle, whiteOutlinedButtonStyle } from '../../../../../styles/MaterialCustomStyles';
import { ListIcon } from '../assets/ListIcon';
import { HierarchyIcon } from '../assets/HierarchyIcon';
import { CustomFormControlLabel } from './StyledFormControl';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';

export const SubdivisionToolBar = (SubdivisionToolBarProps: {
    handleOpenModal: () => void,
    handleSetHierarchy: (isHierarchy: string) => void,
}) => {

    return (
        <Toolbar 
            sx={{
                //padding: '16px 24px', 
                padding: '10px 24px', 
                justifyContent: 'space-between',
            }}>
            <Box 
                sx={{
                    display: 'flex',
                    columnGap: '24px'
                }}>
                <Typography 
                    component={'h2'} 
                    sx={{
                        fontSize:'20px',
                        fontWeight:'500'
                    }}>
                    Подразделения
                </Typography>

                <Button 
                    onClick={() => SubdivisionToolBarProps.handleOpenModal()}
                    variant="contained"  
                    sx={
                        blueContainedButtonStyle
                    }>
                    <AddIcon /> Добавить подразделение
                </Button>
            </Box>

                <RadioGroup 
                    onChange={event => SubdivisionToolBarProps.handleSetHierarchy(event.target.value)}
                    defaultValue={false}
                    sx={{
                        columnGap: '24px'
                    }} row>
                    <CustomFormControlLabel 
                        value={true} 
                        label="Иерархия" 
                        sx={{userSelect: 'none'}}
                        control={
                            <Radio 
                                disableRipple 
                                icon={<ListIcon />} 
                                checkedIcon={<ListIcon style={{fill: '#009FDA'}}/>}/>
                            }/>
                    <CustomFormControlLabel 
                        value={false}
                        label="Список" 
                        sx={{userSelect: 'none'}}
                        control={
                            <Radio 
                                disableRipple 
                                icon={<HierarchyIcon />} 
                                checkedIcon={<HierarchyIcon style={{fill: '#009FDA'}} />}/>
                            }/>
                </RadioGroup>

            <Box 
                sx={{
                    display: 'flex',
                    columnGap: '16px'
                }}>
                <Button 
                    onClick={() => {}}
                    sx={
                        whiteOutlinedButtonStyle
                    }>
                    Редактировать
                </Button>
                
                <Button 
                    onClick={() => {}}
                    sx={{
                        ...whiteOutlinedButtonStyle,
                        padding: '5px 12px',
                        '& .MuiButton-endIcon': {
                            marginLeft: '2px',
                            marginRight: '-7px',
                            fontSize: '17px',
                        },
                    }}
                    endIcon={<KeyboardArrowRightRoundedIcon />}>
                    Ещё
                </Button>
                
            </Box>
      </Toolbar>
    )
}