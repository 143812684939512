import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../../store/hooks/redux"
import { useParams } from "react-router-dom"
import { getSubdivisionsRequest, createSubdivisionRequest, editSubdivisionRequest } from "../../../../services/SubdivisionService"
import { clearSubdivisionBuffer, clearSubdivisions, setSubdivisionBuffer, setSubdivisions } from "./OrganisationSubdivisionsSlice"
import { Subdivision } from "./OrganisationSubdivision-types"
import { OrganisationsTabsLabels } from "../OrganisationAllInfoModule/OrganisationAllInfo-types"
import { has } from "lodash"



export const useSubdivisionsModel = (useSubdivisionModelProps: {
    tabValue: OrganisationsTabsLabels
    setTab: (tab: OrganisationsTabsLabels) => void
}) => {

    const { id } = useParams();

    const [isRequestSucceed, setRequestSucceed] = useState<boolean>(false)

    const [hierarchy, setHierarchy] = useState<boolean>(false)
    const handleSetHierarchy = (value: string) => {
        setHierarchy((/true/i).test(value))
    }


    


    // Open/Close SubdivisionCard.tsx
    const [isModalOpen, setModalOpen] = useState<boolean>(false)

    const handleOpenModal = () => { setModalOpen(true) };


    return {        
        isModalOpen,
        handleOpenModal,


        hierarchy,
        handleSetHierarchy,

        isRequestSucceed,
        setRequestSucceed,
        
        setModalOpen
    }
}